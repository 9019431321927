



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.tip {
  cursor: pointer;
  background: #f2f6fc;
}
.my-btn {
  width: 5rem;
  height: 28px;
  margin-top: 6px;
  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}
.el-upload {
  border: none;
  height: 180px !important;
}
.export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
